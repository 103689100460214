import Home from './pages/Home/Home';
import Community from './pages/Home/Community/Community';

// User Registration | Login

// import UserVerified from './pages/Auth/Verified/user_verified';
import PageNotFound from './components/NotFound/PageNotFound';

// Dashboard
// import Dashboard from './pages/Dashboard/Main/Main';
// import Blogs from './pages/Blogs/Blogs';
// import Events from './pages/Events/Events/Events';
// import CommunityProfile from './pages/Community/Profile/CommunityProfile';

// React Router Dom
import {
  BrowserRouter as Router,
  Route,
  Routes,
  // Navigate,
} from 'react-router-dom';
import CommunitySpace from './pages/communityspace/Communityspace';
// import Search from './pages/Search/Search';
// import CommunityDashboard from './pages/Dashboard/Community/Dashboard';
// import Interests from './pages/profile/interests';
// import ProfileDetails from './pages/profile/profiledetails';
// import ProfileUpdated from './pages/profile/profileupdated';
// import EventSingle from './pages/Events/EventSingle/EventSingle';
import About from './pages/AboutUs/About';
// import CommunitySelection from './pages/profile/community_selection';
// import BlogSingle from './pages/Blogs/Single/BlogSingle';
// import UserProfile from './pages/UserProfile/UserProfile';
// import Footer from './components/Footer/Footer';
// import AddBlog from './pages/Blogs/AddBlog/AddBlog';
// import AddEvent from './pages/Events/AddEvent/AddEvent';
// import Login from './pages/Auth/Login/login';
// import SignUp from './pages/Auth/Register/signup';
// import UserVerification from './pages/Auth/Verification/user_verification';
// import Discussions from './pages/Discussions/Discussions';
// import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { useEffect } from 'react';
import { validUserThunk } from './redux/slices/user';
import Sidebar from './components/Sidebar/Sidebar';

function App() {
  // eslint-disable-next-line
  const { user } = useAppSelector((store) => store.user);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(validUserThunk());
    // console.log('rendered');
  }, [dispatch]);

  return (
    <div className="app">
      <Router>
        <div className="loyout__main">
          <Sidebar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/waitlist/community" element={<Community />} />
            <Route path="/communityspace" element={<CommunitySpace />} />
            <Route path="/about-us" element={<About />} />
            <Route path="*" element={<PageNotFound />} />

            {/* <Route
              path="/login"
              element={
                <ProtectedAuthRoutes user={user}>
                  <Login />
                </ProtectedAuthRoutes>
              }
            />
            <Route
              path="/register"
              element={
                <ProtectedAuthRoutes user={user}>
                  <SignUp />
                </ProtectedAuthRoutes>
              }
            />
            <Route path="/search" element={<Search />} />

            <Route
              path="/dashboard"
              element={
                // <ProtectedRoute user={user}>
                <Dashboard />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/community/dashboard"
              element={<CommunityDashboard />}
            />
            <Route path="/community/:slug" element={<CommunityProfile />} />

            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog/:slug" element={<BlogSingle />} />
            <Route path="/blogs/addblog" element={<AddBlog />} />

            <Route path="/events" element={<Events />} />
            <Route path="/event/:title" element={<EventSingle />} />
            <Route path="/events/addevent" element={<AddEvent />} />

            <Route path="/discussions" element={<Discussions />} />

            <Route path="/profile" element={<ProfileDetails />} />
            <Route path="/verification" element={<UserVerification />} />
            <Route path="/profile/:username" element={<UserProfile />} />
            <Route path="/interests" element={<Interests />} />
            <Route path="/profileUpdate" element={<ProfileUpdated />} />
            <Route path="/verified" element={<UserVerified />} />
            <Route path="/suggestions" element={<CommunitySelection />} />
            <Route
              path="/users/:username/verify/:token"
              element={<UserVerified />}
            />
            <Route
              path="/users/:username/verify/:token"
              element={<UserVerified />}
            /> */}
          </Routes>
          {/* <Footer /> */}
        </div>
      </Router>
    </div>
  );
}

// const ProtectedAuthRoutes = ({ children, user }: any) => {
//   if (user) {
//     // setTimeout(() => {
//     return <Navigate to="/dashboard" replace={true} />;
//     // }, 5000);
//   } else {
//     return children;
//   }
// };

// const ProtectedRoute = ({ children, user }: any) => {
//   if (!user) {
//     setTimeout(() => {
//       return <Navigate to="/" replace={true} />;
//     }, 5000);
//   }
//   return children;
// };

export default App;