import Events from '../../../assets/dashboard/Event.jpeg';
import Blog from '../../../assets/dashboard/blog.png';
import Profile from '../../../assets/dashboard/Potrait.jpeg';
import community from '../../../assets/dashboard/community.png';
import { communityCardType } from '../../../constants/interfaces';
import {
  BiSolidHome,
  BiSolidNews,
  BiTrendingUp,
  BiMessageSquare,
  BiSearch,
} from 'react-icons/bi';
import { PiNewspaperClippingBold } from 'react-icons/pi';
import { IoSettings } from 'react-icons/io5';
import { IconType } from 'react-icons/lib';

interface DashboardEventsDummyType {
  id: number;
  image: string;
  title: string;
  description: string;
  eventMonth: string;
  eventDate: number;
}

const eventsDummy: DashboardEventsDummyType[] = [
  {
    id: 1,
    image: Events,
    title: 'AI Workshop on Advancement in Technology.',
    description:
      'A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal It is a series of vertical.',
    eventMonth: 'APR',
    eventDate: 18,
  },
  {
    id: 2,
    image: Events,
    title: 'AI Workshop on Advancement in Technology.',
    description:
      'A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal It is a series of vertical.',
    eventMonth: 'APR',
    eventDate: 18,
  },
  {
    id: 3,
    image: Events,
    title: 'AI Workshop on Advancement in Technology.',
    description:
      'A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal It is a series of vertical.',
    eventMonth: 'APR',
    eventDate: 18,
  },
  {
    id: 3,
    image: Events,
    title: 'AI Workshop on Advancement in Technology.',
    description:
      'A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal It is a series of vertical.',
    eventMonth: 'APR',
    eventDate: 18,
  },
  {
    id: 3,
    image: Events,
    title: 'AI Workshop on Advancement in Technology.',
    description:
      'A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal It is a series of vertical.',
    eventMonth: 'APR',
    eventDate: 18,
  },
  {
    id: 3,
    image: Events,
    title: 'AI Workshop on Advancement in Technology.',
    description:
      'A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal It is a series of vertical.',
    eventMonth: 'APR',
    eventDate: 18,
  },
];
export const blogDummy = [
  {
    id: 1,
    image: Blog,
    title: 'Grid system for better Design User Interface',
    autor: {
      image: Profile,
      name: 'Jesica koli',
    },
    tags: ['Design', 'Interface'],
    date: '02 december 2022',
    read: '3 min. to read',
    description:
      'A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal It is a series of vertical ...',
    eventMonth: 'APR',
    eventDate: 18,
  },
  {
    id: 1,
    image: Blog,
    title: 'This is the second blog',
    autor: {
      image: Profile,
      name: 'Jesica koli',
    },
    tags: ['Design', 'Interface'],
    date: '02 december 2022',
    read: '3 min. to read',
    description:
      'A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal It is a series of vertical ...',
    eventMonth: 'APR',
    eventDate: 18,
  },
  {
    id: 1,
    image: Blog,
    title: 'this is third blog',
    autor: {
      image: Profile,
      name: 'Jesica koli',
    },
    tags: ['Design', 'Interface'],
    date: '02 december 2022',
    read: '3 min. to read',
    description:
      'A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal It is a series of vertical ...',
    eventMonth: 'APR',
    eventDate: 18,
  },
  {
    id: 1,
    image: Blog,
    title: 'Grid system for better Design User Interface',
    autor: {
      image: Profile,
      name: 'Jesica koli',
    },
    tags: ['Design', 'Interface'],
    date: '02 december 2022',
    read: '3 min. to read',
    description:
      'A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal It is a series of vertical ...',
    eventMonth: 'APR',
    eventDate: 18,
  },
  {
    id: 1,
    image: Blog,
    title: 'Grid system for better Design User Interface',
    autor: {
      image: Profile,
      name: 'Jesica koli',
    },
    tags: ['Design', 'Interface'],
    date: '02 december 2022',
    read: '3 min. to read',
    description:
      'A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal It is a series of vertical ...',
    eventMonth: 'APR',
    eventDate: 18,
  },
  {
    id: 1,
    image: Blog,
    title: 'Grid system for better Design User Interface',
    autor: {
      image: Profile,
      name: 'Jesica koli',
    },
    tags: ['Design', 'Interface'],
    date: '02 december 2022',
    read: '3 min. to read',
    description:
      'A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal It is a series of vertical ...',
    eventMonth: 'APR',
    eventDate: 18,
  },
  {
    id: 1,
    image: Blog,
    title: 'Grid system for better Design User Interface',
    autor: {
      image: Profile,
      name: 'Jesica koli',
    },
    tags: ['Design', 'Interface'],
    date: '02 december 2022',
    read: '3 min. to read',
    description:
      'A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal It is a series of vertical ...',
    eventMonth: 'APR',
    eventDate: 18,
  },
];

export const dummyCommunities: communityCardType[] = [
  {
    id: 1,
    image: Events,
    name: 'TensorFlow',
    members: '2.5K',
    tags: ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4', 'Tag 5'],
    slug: '',
  },
  {
    id: 2,
    image: community,
    name: 'TensorFlow',
    members: '2.5K',
    tags: ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4'],
    slug: '',
  },
  {
    id: 3,
    image: community,
    name: 'TensorFlow',
    members: '2.5K',
    tags: ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4', 'Tag 5'],
    slug: '',
  },
  {
    id: 4,
    image: community,
    name: 'TensorFlow',
    members: '2.5K',
    tags: ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4'],
    slug: '',
  },
  {
    id: 5,
    image: community,
    name: 'TensorFlow',
    members: '2.5K',
    tags: ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4'],
    slug: '',
  },
  {
    id: 6,
    image: community,
    name: 'TensorFlow',
    members: '2.5K',
    tags: ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4'],
    slug: '',
  },
  {
    id: 6,
    image: community,
    name: 'TensorFlow',
    members: '2.5K',
    tags: ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4'],
    slug: '',
  },
  {
    id: 6,
    image: community,
    name: 'TensorFlow',
    members: '2.5K',
    tags: ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4'],
    slug: '',
  },
  {
    id: 6,
    image: community,
    name: 'TensorFlow',
    members: '2.5K',
    tags: ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4'],
    slug: '',
  },
  {
    id: 6,
    image: community,
    name: 'TensorFlow',
    members: '2.5K',
    tags: ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4'],
    slug: '',
  },
  {
    id: 6,
    image: community,
    name: 'TensorFlow',
    members: '2.5K',
    tags: ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4'],
    slug: '',
  },
];
export { eventsDummy };
type sidebarType = {
  id: number;
  name: string;
  icon: IconType;
  to: string;
};
export const sidebarData: sidebarType[] = [
  {
    id: 0,
    name: 'Search',
    icon: BiSearch,
    to: '/search',
  },
  {
    id: 1,
    name: 'Dashboard',
    icon: BiSolidHome,
    to: '/dashboard',
  },

  {
    id: 2,
    name: 'Blogs',
    icon: BiSolidNews,
    to: '/blogs',
  },
  {
    id: 3,
    name: 'Events',
    icon: PiNewspaperClippingBold,
    to: '/events',
  },
  {
    id: 4,
    name: 'Discussion Forum',
    icon: BiMessageSquare,
    to: '/discussions',
  },
  {
    id: 5,
    name: 'Trending',
    icon: BiTrendingUp,
    to: '/trending',
  },
  {
    id: 6,
    name: 'Settings',
    icon: IoSettings,
    to: '/settings',
  },
];
