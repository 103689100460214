import React from 'react';
import styles from './About.module.css';

// Assets
import hero from '../../assets/hero.png';
import tick from '../../assets/about-tick.svg';
import gradient from '../../assets/community_gradient.svg';

// Components & Constants
import Navbar from '../../components/Navbar/Navbar';
import { communityBenefits, team } from '../../constants';

// React Icons
import { GoArrowDownLeft } from 'react-icons/go';
import { MdAlternateEmail } from 'react-icons/md';
import { BsLinkedin, BsTwitter } from 'react-icons/bs';

function About() {
  return (
    <main className={styles.about__page}>

      <Navbar />

      <section className={styles.about__section1}>
        <img className={styles.pink__gradient1} src={gradient} alt="Gradient" />

        <div className={styles.section1__center}>
          <div className={styles.section1__side1}>
            <img src={hero} alt="Hero" />
          </div>
          <div className={styles.section1__side2}>
            <h2>Our Team</h2>
            <p>
              Meet the visionaries crafting the future at AdVantage. Our team's
              shared dream and relentless drive make us more than just
              professionals <br /> – we're pioneers
            </p>
          </div>
        </div>
      </section>

      <section className={styles.about__section2}>
        <div className={styles.section2__center}>
          <h4>Why us</h4>
          <div className={styles.section2__benefits}>
            {communityBenefits.map((benefit) => {
              return (
                <div
                  className={`${styles.benefit__div} ${styles.right__border}`}
                  key={benefit.id}
                >
                  <img src={tick} alt="Tick" />
                  <h3>{benefit.title}</h3>
                  <p>{benefit.description}</p>
                </div>
              );
            })}
            <div className={styles.redirect__team}>
              <h2>Get to know Our Team</h2>
              <GoArrowDownLeft className={styles.arrow__icon} />
            </div>
          </div>
        </div>
      </section>

      <section className={styles.about__section3}>
        <div className={styles.section3__center}>
          <h4>Our Team</h4>

          <div className={styles.section3__members}>
            {team.map((member) => {
              return (
                <div key={member.id} className={styles.section3__imageblock}>
                  
                  <div className={styles.section3__subImageBlock}>
                    <img src={member.photo} alt="Members" />
                    <div className={styles.name__block}>
                      <div>
                        <h2>{member.name}</h2>
                        <h6>{member.position}</h6>
                      </div>
                      <a target="__blanck" href={member.linkidin}>
                        <BsLinkedin className={styles.social__icons} />
                      </a>
                    </div>
                  </div>

                  <div className={styles.photo__overlay}>
                    {/* <h3>{member && member.tagline}</h3> */}
                    <div className={styles.name__block}>
                      <div>
                        <h2>{member.name}</h2>
                        <h6>{member.position}</h6>
                      </div>
                      <div>
                        <a href={`mailto:${member.email}`}>
                          <MdAlternateEmail className={styles.social__icons} />
                        </a>
                        <a target="__blanck" href={member.twitter}>
                          <BsTwitter className={styles.social__icons} />
                        </a>
                        <a target="__blanck" href={member.linkidin}>
                          <BsLinkedin className={styles.social__icons} />
                        </a>
                      </div>
                    </div>
                  </div>
                  
                </div>
              );
            })}
          </div>
        </div>
      </section>

    </main>
  );
}

export default About;
