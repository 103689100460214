// userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
// import { toast } from 'react-toastify';
import {
  googleAuth,
  isValidUser,
  verifyEmailLink,
} from '../../apis/users/auth';
type initialStateType = {
  user: any;
  accessToken: any;
  isLoading: any;
  error: any;
};
let initialState: initialStateType = {
  user: null,
  accessToken: null,
  isLoading: false,
  error: null,
};

export const loginUser = createAsyncThunk(
  'loginAsyncThunk',
  async (body: any) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/user/auth/login`,
        body
      );

      if (data.accessToken) {
        localStorage.setItem('userAccessToken', data.accessToken);
      }

      return data;
    } catch (error: any) {
      throw error.response.data.message || 'An error occurred during login.';
    }
  }
);
export const registerUser = createAsyncThunk(
  'registerAsyncThunk',
  async (body: any) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/user/auth/register`,
        body
      );
      return data;
    } catch (error: any) {
      throw error.response.data.message || 'An error occurred during Register.';
    }
  }
);
export const verifyEmail = createAsyncThunk(
  'verifyEmail',
  async (body: any) => {
    try {
      const data = await verifyEmailLink(body.username, body.token);

      return data;
    } catch (error: any) {
      throw (
        error.response.data.message ||
        'An error occurred during Verifying Email.'
      );
    }
  }
);
export const googleAuthThunk = createAsyncThunk(
  'googleAuth',
  async (token: string) => {
    try {
      const data = await googleAuth(token);
      if (data.token) {
        localStorage.setItem('userAccessToken', data.token);
      }
      return data;
    } catch (error: any) {
      throw (
        error.response.data.message ||
        'An error occurred during Google Authentication.'
      );
    }
  }
);
export const validUserThunk = createAsyncThunk('validUser', async () => {
  try {
    const data = await isValidUser();
    return data;
  } catch (error: any) {
    throw (
      error.response.data.message || 'An error occurred during User Validation.'
    );
  }
});
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.isLoading = true;
      state.accessToken = null;
    });
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.user = payload.user ? payload.user : null;
      state.isLoading = false;
      state.accessToken = payload.accessToken;
    });
    builder.addCase(loginUser.rejected, (state, action: any) => {
      state.user = null;
      state.accessToken = null;
      state.error = action.error.message || 'An error occurred during login.';
      state.isLoading = false;
    });

    // Reducers for registerUser
    builder.addCase(registerUser.pending, (state) => {
      state.isLoading = true;
      state.user = null;
      state.error = null;
      state.accessToken = null;
    });
    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.user = payload.message;
    });
    builder.addCase(registerUser.rejected, (state, action: any) => {
      state.user = null;
      state.accessToken = null;
      state.error = action.error.message || 'An error occurred during login.';
      state.isLoading = false;
    });

    builder.addCase(verifyEmail.pending, (state) => {
      state.isLoading = true;
      state.user = null;
      state.error = null;
    });
    builder.addCase(verifyEmail.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;

      state.user = payload;
    });
    builder.addCase(verifyEmail.rejected, (state, action: any) => {
      state.user = null;
      state.error = action.error.message || 'An error occurred during login.';

      state.isLoading = false;
    });

    builder.addCase(googleAuthThunk.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.accessToken = null;
      state.user = null;
    });
    builder.addCase(googleAuthThunk.fulfilled, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
      state.user = { ...payload.user };
      state.accessToken = payload.token;
    });
    builder.addCase(googleAuthThunk.rejected, (state, action: any) => {
      state.user = null;
      state.accessToken = null;
      state.error =
        action.error.message || 'An error occurred during Google Auth.';

      state.isLoading = false;
    });
    builder.addCase(validUserThunk.pending, (state) => {
      state.isLoading = true;
      state.user = null;
      state.accessToken = null;
      state.error = null;
    });
    builder.addCase(validUserThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.user = payload ? payload.user : null;
      state.accessToken = payload?.accessToken;
      state.error = null;
    });
    builder.addCase(validUserThunk.rejected, (state, action: any) => {
      state.user = null;
      state.accessToken = null;
      state.error =
        action.error.message || 'An error occurred during User Validation.';

      state.isLoading = false;
    });
  },
});

export default userSlice.reducer;
