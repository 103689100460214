import { useState } from 'react';
import styles from './Community.module.css';
import rocketImage from '../../../assets/hero_rocket.png';
import { benefits } from '../../../constants';
import gradient from '../../../assets/community_gradient.svg';
import { communityFormType } from '../../../constants/interfaces';
import { communityWaitlist } from '../../../apis/waitlist';
import { toast } from 'react-toastify';
import Lottie from 'lottie-react';
import loadingIcon from '../../../assets/loading.json';
import Navbar from '../../../components/Navbar/Navbar';

export const defaultFormData: communityFormType = {
  name: '',
  email: '',
  social: [],
  leadName: '',
  leadPhoneNumber: '',
};

function Community() {
  const {
    community__waitlist__page,
    hero__section,
    hero__section__left,
    hero__section__right,
    // countdown__block,
    // countdown__innerblock,
    // countdown__header,
    // individual__countdown,
    // countdown,
    // countdown__type,
    // countdown__number,
    section2__innerblock,
    section2__block,
    section2,
    section2__benefit,
    pink__gradient,
    section3__block,
    section3,
    section3__right,
    section3__left,
    section3__innerblock,
    section4,
    section4__block,
    section4__community__info,
    section4__lead__info,
    section4__form,
    pink__gradient2,
    loading__icon,
  } = styles;

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<communityFormType>(defaultFormData);

  const handleOnChange = (e: any) => {
    setFormData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const data: any = await communityWaitlist(formData);

    if (data?.message) {
      toast.success("Welcome to the waitlist! You're in");
      setFormData(defaultFormData);
      setLoading(false);
    }
    setLoading(false);
  };

  // const [timer, setTimer] = useState<TimerType>(calculateTimeLeft());

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTimer(calculateTimeLeft());
  //   }, 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  return (
    <main className={community__waitlist__page} id="top">
      <Navbar />
      <section className={hero__section}>
        <div className={hero__section__left}>
          <h1>
            Stay ahead,
            <br /> Maintain <span> AdVantage</span>
          </h1>
          <p>
            Get early access to an enhanced community experience for your
            community
          </p>
          <a href="#form">
            <button>Join Now</button>
          </a>
        </div>
        {/* <img className={pink__gradient} src={gradient} alt="Gradient" /> */}
        <div className={hero__section__right}>
          <img src={rocketImage} alt="Rocket" />

          {/* <div className={countdown__block}>
            <div className={countdown__header}>READY TO LAUNCH IN</div>

            <div className={countdown__innerblock}>
              <div className={countdown}>
                <div className={individual__countdown}>
                  <p className={countdown__type}>Days</p>
                  <p className={countdown__number}>{timer.days}</p>
                </div>
              </div>
              <div className={countdown__number}>:</div>

              <div className={countdown}>
                <div className={individual__countdown}>
                  <p className={countdown__type}>Hours</p>
                  <p className={countdown__number}>{timer.hours}</p>
                </div>
              </div>
              <div className={countdown__number}>:</div>

              <div className={countdown}>
                <div className={individual__countdown}>
                  <p className={countdown__type}>Minutes</p>
                  <p className={countdown__number}>{timer.minutes}</p>
                </div>
              </div>
              <div className={countdown__number}>:</div>

              <div className={countdown}>
                <div className={individual__countdown}>
                  <p className={countdown__type}>Seconds</p>
                  <p className={countdown__number}>{timer.seconds}</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>

      <section className={section2}>
        <img className={pink__gradient2} src={gradient} alt="Gradient" />

        <div className={section2__block}>
          <img className={pink__gradient} src={gradient} alt="Gradient" />
          <h6>Benefits of waitlist</h6>
          <div className={section2__innerblock}>
            {benefits.map((benefit) => {
              return (
                <div key={benefit.id} className={section2__benefit}>
                  <div>
                    <img src={benefit.image} alt="Benefits" />
                  </div>
                  <p>{benefit.benefit}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className={section3}>
        <div className={section3__block}>
          <p>About</p>
          <div className={section3__innerblock}>
            <div className={section3__left}>
              <h2>Enhance the community experience</h2>
            </div>
            <div className={section3__right}>
              <p>
                Introducing our revolutionary community web application,
                designed to empower technical groups to effortlessly connect
                with their target audience.
                <br />
                <br />
                Overcome challenges like cluttered representation and gain
                access to exclusive features such as a dedicated event space
                tailored for community activities.
                <br />
                <br />
                Built on an entirely open-source platform, both members and
                communities can enjoy free and unlimited access to all offered
                services.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={section4}>
        <div className={section4__block} id="form">
          <h2>Join the race of technology by filling out this application.</h2>
          <form
            onSubmit={handleOnSubmit}
            className={section4__form}
            method="POST"
          >
            <div className={section4__community__info}>
              <label>Community Details</label>
              <input
                onChange={handleOnChange}
                name="name"
                placeholder="Name"
                value={formData.name}
                required
              />
              <input
                onChange={handleOnChange}
                name="email"
                placeholder="Email"
                value={formData.email}
                required
              />
              <input
                onChange={handleOnChange}
                name="social"
                placeholder="Social Link"
                value={formData.social}
                required
              />
            </div>
            <div className={section4__lead__info}>
              <label>Community Representative Details</label>
              <input
                onChange={handleOnChange}
                name="leadName"
                placeholder="Name"
                value={formData.leadName}
                required
              />
              <input
                onChange={handleOnChange}
                name="leadPhoneNumber"
                placeholder="Phone Number"
                value={formData.leadPhoneNumber}
                required
              />
              {!loading ? (
                <button>Join Now </button>
              ) : (
                <Lottie
                  className={loading__icon}
                  animationData={loadingIcon}
                  loop={true}
                />
              )}
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default Community;
