import React, { useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import AdVlogo from '../../assets/AdVLogo2.svg';
import eDAMlogo from '../../assets/eDAM.jpg';
import Modal from './modal';
import styles from '../communityspace/Communityspace.module.css';
import { BsFillPeopleFill } from "react-icons/bs";

interface Community {
  img: string;
  title: string;
  about: string;
  tags: string[];
  people: string;
}

const communities: Community[] = [
  {
    img: AdVlogo,
    title: 'AdVantage',
    about: 'For students and communities alike to find a common ground and experience the open-source way of learning',
    tags: ['AIML', 'Open-Source', 'Workshops'],
    people: '100+',
  },
  {
    img: eDAMlogo,
    title: 'eDAM',
    about: "Hyderabad's tech community, bridging the gap between college environment and corporate world serving as engineering accelerators.",
    tags: ['AIML', 'Data Science', 'Web Dev'],
    people: '200+',
  },
  {
    img: AdVlogo,
    title: 'AdVantage',
    about: 'For students and communities alike to find a common ground and experience the open-source way of learning',
    tags: ['AIML', 'Open-Source', 'Workshops'],
    people: '100+',
  },
];

const CommunitySpace: React.FC = () => { 
  const [showModal, setShowModal] = useState(false);
  const [selectedCommunity, setSelectedCommunity] = useState<Community | null>(null);

  const handleExploreClick = (community: Community) => {
    setSelectedCommunity(community);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCommunity(null);
  };

  return (
    <>
      <Navbar/>
      <div className={styles.page_container}>
        <h1>Explore the Community Space</h1>
        <div className={styles.community_grid}>
          {communities.map((community, index) => (
            <div key={index} className={styles.community_card}>
              <div className={styles.communitycover}>
                <img src={community.img} alt={`${community.title}-thumbnail`} />
                <p><BsFillPeopleFill/> {community.people}</p>
              </div>
              <h2>{community.title}</h2>
              <p>{community.about}</p>
              <h4>Tags</h4>
              <div className={styles.tags}>
                {community.tags.map((tag, tagIndex) => (
                  <p key={tagIndex} className={styles.tag_item}>{tag}</p>
                ))}
              </div>
              <button
                className={styles.explore_btn}
                onClick={() => handleExploreClick(community)}
              >
                Explore
              </button>
            </div>
          ))}
        </div>
      </div>
      {showModal && (
        <Modal community={selectedCommunity} onClose={handleCloseModal} />
      )}
    </>
  );
}

export default CommunitySpace;
