import styles from './Home.module.css';
import React, { useState } from 'react';

// import Icon1 from '../../assets/HeroIcon1.svg';
// import Icon2 from '../../assets/HeroIcon2.svg';
// import Icon3 from '../../assets/HeroIcon3.svg';

import FIcon1 from '../../assets/FormIcon1.svg';
import FIcon2 from '../../assets/FormIcon2.svg';
import FIcon3 from '../../assets/FormIcon3.svg';
import FIcon4 from '../../assets/FormIcon4.svg';

import video1 from '../../assets/video1.mp4';
import video2 from '../../assets/video2.mp4';

import AboutPic from '../../assets/About.png';
import DIcon1 from '../../assets/DetailsIcon1.svg';
import DIcon2 from '../../assets/DetailsIcon2.svg';
import loadingIcon from '../../assets/loading.json';

// React Toastify & Lottie
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';

// Components, Interfaces & Constants
import Navbar from '../../components/Navbar/Navbar';
import { userFormType } from '../../constants/interfaces';
import { homeSupports, homeFAQ } from '../../constants';

// API
import { userWaitlist } from '../../apis/waitlist';

const Home = () => {
  const {
    app__home,
    home__section1,
    section1__center,
    section1__side1,
    side1__btns,
    btns__main,
    section1__side2,
    home__section2,
    section2__center,
    section2__main,
    home__section3,
    section3__center,
    section3__side1,
    section3__side2,
    home__section4,
    section4__center,
    section4__side1,
    section4__side2,
    home__section5,
    section5__center,
    section5__main,
    home__section6,
    section6__center,
    section6__main,
    home__section7,
    section7__center,
    section7__form,
    form__ins,
    icon1,
    icon2,
    home__section8,
    section8__center,
    faq__main,
    faq__box,
    q__box,
    a__box,
    active,
    // countdown__block,
    // countdown__innerblock,
    // countdown__header,
    // individual__countdown,
    // countdown,
    // countdown__type,
    // countdown__number,
    // countdown__prizeicon,
    // countdown__chaticon,
    // countdown__peopleicon,
    hero__header,
    wave__border,
    section4__side2__flex,
    icon3,
    icon4,
    section8__gradient,
    section7__gradient,
    loading__icon,
  } = styles;

  const defaultFormData: userFormType = {
    firstName: '',
    lastName: '',
    email: '',
  };

  // const [timer, setTimer] = useState<TimerType>(calculateTimeLeft());

  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTimer(calculateTimeLeft());
  //   }, 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  const [faqOn, setFaqOn] = useState<number>(1);
  const [formData, setFormData] = useState<userFormType>(defaultFormData);

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const data: any = await userWaitlist(formData);

    if (data?.message) {
      toast.success("Welcome to the waitlist! You're in");
      setFormData(defaultFormData);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  return (
    <main className={app__home}>
      <Navbar />

      {/* Section 1 : Hero with Timer */}

      <section className={home__section1}>
        <div className={section1__center}>
          <div className={section1__side1}>
            <h1 className={hero__header}>
              Explore, Learn, Grow <br />
              <span>AdVantage </span>
              Community
            </h1>
            <div className={side1__btns}>
              <div className={btns__main}>
                <button type="button">
                  <a href="#join">I'm Student</a>
                </button>
                <button type="button">
                  <a href="/waitlist/community">I'm Community</a>
                </button>
              </div>
              <p>The Colossal Community : A community for all communities</p>
            </div>
          </div>

          <div className={section1__side2}>
            {/* Timer Box */}
            {/* <div className={countdown__block}>
              <div className={countdown__header}>READY TO LAUNCH IN</div>

              <div className={countdown__innerblock}>
                <div className={countdown}>
                  <div className={individual__countdown}>
                    <p className={countdown__type}>Days</p>
                    <p className={countdown__number}>{timer.days}</p>
                  </div>
                </div>
                <div className={countdown__number}>:</div>

                <div className={countdown}>
                  <div className={individual__countdown}>
                    <p className={countdown__type}>Hours</p>
                    <p className={countdown__number}>{timer.hours}</p>
                  </div>
                </div>
                <div className={countdown__number}>:</div>

                <div className={countdown}>
                  <div className={individual__countdown}>
                    <p className={countdown__type}>Minutes</p>
                    <p className={countdown__number}>{timer.minutes}</p>
                  </div>
                </div>
                <div className={countdown__number}>:</div>

                <div className={countdown}>
                  <div className={individual__countdown}>
                    <p className={countdown__type}>Seconds</p>
                    <p className={countdown__number}>{timer.seconds}</p>
                  </div>
                </div>
              </div>

              <img className={countdown__prizeicon} src={Icon2} alt="Icon" />
              <img className={countdown__chaticon} src={Icon1} alt="Icon" />
              <img src={Icon3} alt="Icon" className={countdown__peopleicon} />
            </div> */}
            <div className={styles.advantage__video}>
              <iframe
                src="https://www.youtube.com/embed/-tOC9-JCKGY?autoplay=1&controls=0"
                allowFullScreen
                title="AdVantage"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <div className={wave__border}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#f2eee9"
            fillOpacity="1"
            d="M0,96L30,112C60,128,120,160,180,149.3C240,139,300,85,360,80C420,75,480,117,540,122.7C600,128,660,96,720,90.7C780,85,840,107,900,144C960,181,1020,235,1080,229.3C1140,224,1200,160,1260,128C1320,96,1380,96,1410,96L1440,96L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
          ></path>
        </svg>
      </div>

      {/* Section 2 : Supported By */}

      <section className={home__section2}>
        <div className={section2__center}>
          <h2>AdVantage is supported by</h2>
          <div className={section2__main}>
            {homeSupports.map((item) => {
              return (
                <img key={item.id} src={item.image} alt={item.id.toString()} />
              );
            })}
          </div>
        </div>
      </section>

      {/* Section 3 : About */}

      <section className={home__section3} id="about">
        <div className={section3__center}>
          <div className={section3__side1}>
            <h2>
              About <span>AdVantage Community</span>
            </h2>
            <p>
              For a student, AdVantage Community is the place to go for
              navigating the labyrinth of communities and finding their place in
              those that suit them.
            </p>
            <p>
              Students and Communities can find their safe haven away from
              social media’s clutter to better represent their purpose to their
              targeted audience, here at AdVantage.
            </p>
          </div>
          <div className={section3__side2}>
            <img src={AboutPic} alt="About AdVantage" />
          </div>
        </div>
      </section>

      {/* Section 4 : Details */}

      <section className={home__section4}>
        <div className={section4__center}>
          <div className={section4__side1}>
            <img src={DIcon1} alt="Details" />
            <img src={DIcon2} alt="Details" />
          </div>
          <div className={section4__side2__flex}>
            <div className={section4__side2}>
              <h4>Authentic</h4>
              <p>
                Find the most active and top-notch communities with the
                resources you’re looking for and start your learning journey by
                joining us now !
              </p>
            </div>

            <div className={section4__side2}>
              <h4>Open Source</h4>
              <p>
                AdVantage thrives as open-source, fostering collaboration. Its
                accessible community option enhances customization, benefiting
                users' collective experience and innovation.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section 5 : Community */}

      <section className={home__section5}>
        <div className={section5__center}>
          <video autoPlay={true} muted>
            <source src={video1} type="video/mp4" />
          </video>

          <div className={section5__main}>
            <h3>Community</h3>
            <p>
              Discover growth for your community at AdVantage. It's the central
              hub, connecting communities with a vast and fitting audience.
              Elevate your experience – join us to expand horizons and connect
              with students effectively.
            </p>
            <button type="button">
              <a href="/waitlist/community">Join Now</a>
            </button>
          </div>
        </div>
      </section>

      {/* Section 6 : Members */}

      <section className={home__section6}>
        <div className={section6__center}>
          <div className={section6__main}>
            <h3>Members</h3>
            <p>
              Join AdVantage to excel in the tech race with peers. Explore
              countless student and tech communities, find your interests, and
              choose your path on our dynamic platform for guaranteed growth.
            </p>
            <button type="button" title="Join Now">
              <a aria-hidden href="#join">
                Join Now
              </a>
            </button>
          </div>
          <video autoPlay={true} muted>
            <source src={video2} type="video/mp4" />
          </video>
        </div>
      </section>

      {/* Section 8 : FAQ */}

      <section className={home__section8} id="faq">
        <div className={section8__center}>
          {/* Head */}
          <h2>Frequently Asked Questions</h2>

          {/* FAQ's */}
          <div className={faq__main}>
            {homeFAQ.map((item) => {
              return (
                <div
                  key={item.id}
                  className={`${faq__box} ${faqOn === item.id ? active : ''}`}
                >
                  <div className={q__box}>
                    <div>
                      <span>{item.id < 10 ? `0${item.id}` : item.id}</span>
                      <p>{item.question}</p>
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        if (faqOn !== item.id) {
                          setFaqOn(item.id);
                        } else {
                          setFaqOn(0);
                        }
                      }}
                      className={`${faqOn === item.id ? active : ''}`}
                    >
                      {faqOn === item.id ? ' − ' : '+'}
                    </button>
                  </div>
                  <div
                    className={`${a__box} ${faqOn === item.id ? active : ''}`}
                  >
                    <p>{item.answer}</p>
                  </div>
                </div>
              );
            })}
          </div>
          {/* Backgroud Gradient */}
          <svg
            className={section8__gradient}
            xmlns="http://www.w3.org/2000/svg"
            width="1311"
            height="1267"
            viewBox="0 0 1311 1267"
            fill="none"
          >
            <g opacity="0.2" filter="url(#filter0_f_76_535)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M443.365 264.432C531.111 278.06 613.675 298.516 682.702 350.461C761.512 409.769 853.688 477.942 851.977 571.925C850.275 665.368 742.797 716.972 674.768 786.048C600.728 861.227 553.158 981.866 443.365 986.822C332.59 991.822 254.341 890.408 188.229 808.009C133.133 739.342 122.983 656.661 110.471 571.925C94.7148 465.212 25.1017 337.214 107.479 261.122C189.857 185.029 328.572 246.603 443.365 264.432Z"
                fill="#4FA9E2"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_76_535"
                x="-390.333"
                y="-234.333"
                width="1700.67"
                height="1679.67"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="229.167"
                  result="effect1_foregroundBlur_76_535"
                />
              </filter>
            </defs>
          </svg>
        </div>
      </section>

      {/* Section 7 : Waitlist form */}

      <section className={home__section7} id="join">
        <div className={section7__center}>
          <form onSubmit={handleOnSubmit} className={section7__form}>
            <h3>Join Waitlist</h3>
            <div className={form__ins}>
              <input
                onChange={handleOnChange}
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                required
              />
              <input
                onChange={handleOnChange}
                type="text"
                name="lastName"
                value={formData.lastName}
                placeholder="Last Name"
                required
              />
              <input
                onChange={handleOnChange}
                type="text"
                name="email"
                placeholder="Email"
                value={formData.email}
                required
              />
            </div>
            {!loading ? (
              <button>Join Now </button>
            ) : (
              <Lottie
                className={loading__icon}
                animationData={loadingIcon}
                loop={true}
              />
            )}
            <p>
              By Joining, you agree to AdVantage community’s Policies and Terms
              of use
            </p>
            <img src={FIcon1} className={icon1} alt="Icon" />
            <img src={FIcon2} alt="Icon" className={icon2} />
            <img src={FIcon3} alt="Icon" className={icon3} />
            <img src={FIcon4} alt="Icon" className={icon4} />
          </form>
          <svg
            className={section7__gradient}
            xmlns="http://www.w3.org/2000/svg"
            width="1311"
            height="1267"
            viewBox="0 0 1311 1267"
            fill="none"
          >
            <g opacity="0.2" filter="url(#filter0_f_76_535)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M443.365 264.432C531.111 278.06 613.675 298.516 682.702 350.461C761.512 409.769 853.688 477.942 851.977 571.925C850.275 665.368 742.797 716.972 674.768 786.048C600.728 861.227 553.158 981.866 443.365 986.822C332.59 991.822 254.341 890.408 188.229 808.009C133.133 739.342 122.983 656.661 110.471 571.925C94.7148 465.212 25.1017 337.214 107.479 261.122C189.857 185.029 328.572 246.603 443.365 264.432Z"
                fill="#4FA9E2"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_76_535"
                x="-390.333"
                y="-234.333"
                width="1700.67"
                height="1679.67"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="229.167"
                  result="effect1_foregroundBlur_76_535"
                />
              </filter>
            </defs>
          </svg>
        </div>
      </section>
    </main>
  );
};

export default Home;
