import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { store } from './store';
import { GoogleOAuthProvider } from '@react-oauth/google';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="1085860234013-kqvs165lfo5hev028dqsbirqnrpbbc0o.apps.googleusercontent.com">
      <Provider store={store}>
        <App />
      </Provider>
      <ToastContainer position="top-right" />
    </GoogleOAuthProvider>
  </React.StrictMode>
);
