import { createSlice } from '@reduxjs/toolkit';
interface event {
  events: any;
  selectedEvent: any;
  isLoading: boolean;
  error: null | string;
}
const initialState: event = {
  events: [],
  selectedEvent: null,
  isLoading: false,
  error: null,
};
// export const;
const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    selectedBlog: (state, { payload }) => {
      state.isLoading = true;
      state.selectedEvent = payload;
    },
  },
  extraReducers: {},
});
export default eventSlice.reducer;
