import React from 'react';
import { BsFillPeopleFill } from "react-icons/bs";
import styles from './modal.module.css';

interface Community {
  img: string;
  title: string;
  about: string;
  tags: string[];
  people: string;
}

interface ModalProps {
  community: Community | null;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ community, onClose }) => {
  if (!community) return null;

  return (
    <div className={styles.modal_overlay}>
      <div className={styles.modal}>
        <button className={styles.close_button} onClick={onClose}>X</button>
        <div className={styles.modal_content}>
            <div className={styles.modal_header} >
                <img src={community.img} alt={`${community.title}-thumbnail`} />
                <h1>{community.title}</h1>
            </div>
          <p><BsFillPeopleFill/> {community.people}</p>
          <p>{community.about}</p>
          <h4>Tags</h4>
          <div className={styles.tags}>
            {community.tags.map((tag, index) => (
              <p key={index} className={styles.tag_item}>{tag}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
