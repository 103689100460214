// rootReducer.js
import { combineReducers } from 'redux';

import userReducer from './user';
import eventSlice from './eventSlice';
const rootReducer = combineReducers({
  user: userReducer,
  event: eventSlice,
  // Add other slice reducers here if needed
});

export default rootReducer;
