import { useState } from 'react';
import styles from './Sidebar.module.css';

// React Router DOM
import { Link, useLocation } from 'react-router-dom';

// Assets
import AdV from '../../assets/AdVLogo2.svg';

// React Icons
import { FiMenu } from 'react-icons/fi';

import { sidebarData } from '../../pages/Dashboard/constants';
import { useAppSelector } from '../../redux/hooks';
import Potrait from '../../assets/dashboard/Profile.png';

const Sidebar = () => {
  const { pathname } = useLocation();
  // console.log(pathname.toString(), '/', pathname.toString() === '/');
  console.log(pathname.toString() === '/');
  const [toggle, setTogle] = useState(false);
  const handleFullMenuTogle = () => {
    setTogle(!toggle);
  };
  const hideSidebar =
    pathname === '/' ||
    pathname === '/about-us' ||
    pathname === '/waitlist/community' ||
    pathname === '/login' ||
    pathname === '/register' ||
    pathname === '/verification' ||
    pathname === '/verified';
  const [smMenu, setSmMenu] = useState<boolean>(false);
  const handleSmallScreenToggle = () => {
    setSmMenu(!smMenu);
  };

  const { user }: any = useAppSelector((store) => store.user);

  const {
    dashboard__sidebar,
    sidebar__main,
    sidebar__logo,
    sidebar__menu,
    page__active,
    sidebar__options,
    profile,
    community__terms,
    community__classic,
    copyright,
    active,
    hide,
    smscreen__menu,
  } = styles;
  if (hideSidebar) {
    return <></>;
  } else {
    return (
      <>
        <div className={styles.navbar__mobile}>
          <button
            type="button"
            title="Toggle Menu"
            className={smscreen__menu}
            onClick={handleSmallScreenToggle}
          >
            <FiMenu />
          </button>
          <div className={sidebar__logo}>
            <img src={AdV} alt="AdVantage" />
            <p>AdVantage Community</p>
          </div>
        </div>

        <nav
          className={`${dashboard__sidebar} ${!toggle && active} ${
            smMenu || hide
          }`}
        >
          <aside className={sidebar__main}>
            {/* Navigations */}
            <div
              className={`${
                toggle
                  ? ` ${styles.sidebar__menu__container} ${styles.sidebar__menu__container__active}`
                  : styles.sidebar__menu__container
              }`}
            >
              <div className={styles.nav__bar}>
                <div className={sidebar__logo}>
                  <img
                    onClick={
                      window.innerWidth > 1100
                        ? handleFullMenuTogle
                        : handleSmallScreenToggle
                    }
                    src={AdV}
                    alt="AdVantage"
                  />
                  <p className={!toggle ? styles.hidden : ''}>
                    AdVantage Community
                  </p>
                </div>
                <div className={styles.sidenav}></div>
              </div>
              <ul className={sidebar__menu}>
                {sidebarData.map((data) => {
                  return (
                    <li key={data.id}>
                      <Link
                        onClick={() => {
                          setSmMenu(false);
                        }}
                        className={pathname === data.to ? page__active : ''}
                        to={data.to}
                      >
                        <data.icon fontSize={24} />
                        <p className={!toggle ? styles.hidden : ''}>
                          {data.name}
                        </p>
                      </Link>
                    </li>
                  );
                })}
              </ul>

              <div className={sidebar__options}>
                {!user ? (
                  <button className={styles.login__btn}>
                    <Link to="/login">Login</Link>
                  </button>
                ) : (
                  <div
                    style={{
                      borderBottom: !toggle
                        ? ''
                        : '2px solid rgba(189, 197, 205, 0.5)',
                      borderTop: !toggle
                        ? ''
                        : '2px solid rgba(189, 197, 205, 0.5)',
                    }}
                    className={profile}
                  >
                    <img
                      src={user ? user.profilePhoto : Potrait}
                      alt="Potrait"
                    />
                    <div
                      className={
                        !toggle
                          ? `${styles.hidden} ${styles.profile__details}`
                          : ''
                      }
                    >
                      <Link
                        onClick={() => {
                          setSmMenu(false);
                        }}
                        to="/userprofile"
                      >
                        <h4>{user?.firstName + ' ' + user?.lastName}</h4>
                        {user.username}
                      </Link>
                    </div>
                  </div>
                )}
                <div
                  className={!toggle ? `${styles.hidden}` : community__terms}
                >
                  <div className={community__classic}>
                    <Link to="/dashboard">Terms & Conditions</Link>
                    <Link to="/dashboard">Agenda</Link>
                    <Link to="/dashboard">Legals</Link>
                  </div>
                  <div className={copyright}>
                    Copyright &copy; 2023 AdVantageAI, Inc.
                  </div>
                </div>
              </div>
            </div>

            {/* Small screen Menu */}

            {/* <div className={`${smscreen__menu} ${smMenu && active}`}>
            <button
              type="button"
              onClick={() => {
                setSmMenu(!smMenu);
              }}
              className={`${smMenu && active}`}
            >
              <RiMenuUnfoldLine fontSize={22} />
            </button>
          </div> */}
          </aside>
        </nav>
      </>
    );
  }
};

export default Sidebar;
