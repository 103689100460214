import React from 'react';
import './PageNotFound.css';
import Navbar from '../Navbar/Navbar';
import { Link } from 'react-router-dom';


function PageNotFound() {
  return (
    <>
      <main className="page__404">
        <Navbar />
        <section className="text__404">
          <h1>Page Not Found | 404</h1>
          <p>May be you're in a broken URL. Back to Home Page</p>
          <Link to={"/"}>Go back Home</Link>
        </section>
      </main>
    </>
  );
}

export default PageNotFound;
