import { useState } from 'react';
import styles from './Navbar.module.css';
import Logo from '../../assets/AdVLogo.svg';
import { Twirl as Hamburger } from 'hamburger-react';
import { Link } from 'react-router-dom';

function Navbar() {
  const {
    app__navbar,
    nav__center,
    nav__logo,
    nav__sidebar,
    nav__menu,
    nav__sidebar__ul,
    displayNone,
  } = styles;

  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <nav className={app__navbar}>
      <div className={nav__center}>
        {/* Logo */}
        <div className={nav__logo}>
          <Link to="/">
            <img src={Logo} alt="AdVantage" />
          </Link>
        </div>

        {/* Title : AdVantage Digital */}
        <div className={nav__sidebar}>
          <div className={nav__menu}>
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </div>
          <ul className={isOpen ? nav__sidebar__ul : displayNone}>
            <li>
              <Link to="/about-us">About</Link>
            </li>
            <li>
              <Link to="/">Member</Link>
            </li>
            <li>
              <Link to="/waitlist/community">Community</Link>
            </li>
            <li>
              <Link to="/communityspace">Community Space</Link>
            </li>
            <li>
              <a target="_blank" rel="noopener noreferrer" href="https://dashboard.advantagecommunity.in">
                Beta Version
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
