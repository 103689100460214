import S1 from '../assets/Suzuki.svg';
import S2 from '../assets/microsoft.png';
import S3 from '../assets/ciie.png';
import S4 from '../assets/Thub.svg';
import bicon1 from '../assets/benefits_icon1.png';
import bicon2 from '../assets/benefits_icon2.png';
import bicon3 from '../assets/benefits_icon3.png';
import profile from '../assets/dashboard/Potrait.jpeg';
// import hk from '../assets/team/hk.jpg';
import mamtha from '../assets/team/mamtha.jpeg';
import rohit from '../assets/team/rohit.jpg';
// import riktha from '../assets/team/riktha.jpeg';
import sneha from '../assets/team/sneha.jpg';
import anjana from '../assets/team/anjana.jpg';
import nayan from '../assets/team/nayan.jpeg';
// import farhan from '../assets/team/farhan.jpeg';

interface HomeSupportType {
  id: number;
  image: string;
}

interface HomeFAQType {
  id: number;
  question: string;
  answer: string;
}

const homeSupports: HomeSupportType[] = [
  {
    id: 1,
    image: S1,
  },
  {
    id: 2,
    image: S2,
  },
  {
    id: 3,
    image: S3,
  },
  {
    id: 4,
    image: S4,
  },
];

const homeFAQ: HomeFAQType[] = [
  {
    id: 1,
    question: 'What is AdVantage Community ?',
    answer:
      'AdVantage Community is a platform for students and communities alike to find a common ground , to share and learn through an open-source way of learning with everyone sharing the same interests.',
  },
  {
    id: 2,
    question: 'Would being part of this community mean I get paid ?',
    answer:
      'No incentives will be provided, although the content you get exposed to on this platform will lead you to build a good portfolio and get you access to numerous opportunities which will inevitably add to your learning and career development.',
  },
  {
    id: 3,
    question: 'What are the benefits of joining the community ?',
    answer:
      'By joining our community , get the exclusive access to learn about every other community of your interest out in the technical world. You can use the opportunity to elevate the way you understand and learn what you like to with the help of the right people at the right place.',
  },
  {
    id: 4,
    question: 'How is it different from other communities ?',
    answer:
      'In contrast to following a particular domain of interest , AdVantage is a dynamic platform provided for all students and student and technical communities alike. A student gets access to all communities and the communities get access to students, all in one place We support the learning process by increasing the awareness and importance of open-source and a real life application of the initiative in question',
  },
];
const benefits = [
  {
    id: 1,
    benefit: 'Direct and exclusive access to application',
    image: bicon1,
  },
  {
    id: 2,
    benefit: 'Get featured on prime time ',
    image: bicon2,
  },
  {
    id: 3,
    benefit: 'Be the first to make a change',
    image: bicon3,
  },
];
const calculateTimeLeft = () => {
  const targetDate = new Date('2023-10-12T00:00:00');
  const now = new Date();
  const timeDifference = targetDate.getTime() - now.getTime();

  if (timeDifference <= 0) {
    // Countdown reached, set timer to all zeros
    return {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    };
  }

  const seconds = Math.floor((timeDifference / 1000) % 60);
  const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
  const hours = Math.floor((timeDifference / 1000 / 60 / 60) % 24);
  const days = Math.floor(timeDifference / 1000 / 60 / 60 / 24);

  return {
    days: days.toString().padStart(2, '0'),
    hours: hours.toString().padStart(2, '0'),
    minutes: minutes.toString().padStart(2, '0'),
    seconds: seconds.toString().padStart(2, '0'),
  };
};
export { homeSupports, homeFAQ, benefits, calculateTimeLeft };
export const profileDummyData = [
  {
    name: 'John Doe shhdh',
    description: 'Frontend Developer',
    image: profile,
  },
  {
    name: 'John Doe',
    description: 'Frontend Developer',
    image: profile,
  },
  {
    name: 'John Doe',
    description: 'Frontend Developer',
    image: profile,
  },
  {
    name: 'John Doe',
    description: 'Frontend Developer',
    image: profile,
  },
  {
    name: 'John Doe',
    description: 'Frontend Developer',
    image: profile,
  },
  {
    name: 'John Doe',
    description: 'Frontend Developer',
    image: profile,
  },
  {
    name: 'John Doe',
    description: 'Frontend Developer',
    image: profile,
  },
  {
    name: 'John Doe',
    description: 'Frontend Developer',
    image: profile,
  },
  {
    name: 'John Doe',
    description: 'Frontend Developer',
    image: profile,
  },
  {
    name: 'John Doe',
    description: 'Frontend Developer',
    image: profile,
  },
];
export function convertToTitleCase(inputString: string) {
  // Split the input string by hyphens
  const words = inputString.split('-');

  // Capitalize the first letter of each word and join them with a space
  const titleCaseString = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return titleCaseString;
}
export const communityBenefits = [
  {
    id: 14,
    title: 'Experienced Core Team',
    description:
      'Our team has experienced members active in reputable communities.',
  },
  {
    id: 83,
    title: 'Technical Development and Management Expertise',
    description:
      'Has acquired robust technical development and management skills through active roles.',
  },
  {
    id: 17,
    title: 'Passion for Entrepreneurship',
    description:
      'Enthusiastic about entrepreneurship, indicating a drive to create and innovate.',
  },
  {
    id: 5,
    title: 'Focus on Efficient Learning',
    description:
      'Our Primary aim is to reimagine learning, highlighting a dedication to educational evolution.',
  },
  {
    id: 34,
    title: 'Aspiring Success',
    description:
      'Dedicated to steady progress, showcasing genuine ambition and determination.',
  },
];
export const team = [
  {
    id: 32,
    photo: nayan,
    name: 'Nayan Kanaparthi',
    linkidin: 'https://www.linkedin.com/in/nayan-kanaparthi-7383a2183/',
    twitter: 'https://twitter.com/NayanKanaparthi',
    position: 'CEO, Co-Founder',
    email: 'kanaparthinayan@gmail.com',
  },
  // {
  //   id: 20,
  //   photo: hk,
  //   name: 'Hrushikesh Dokala',
  //   linkidin: 'https://www.linkedin.com/in/hrushikeshdokala/',
  //   twitter: 'http://x.com/hrushikeshhhh',
  //   position: 'COO, Co-Founder',
  //   email: 'hrushikeshdokala@advantagecommunity.in',
  // },
  // {
  //   id: 95,
  //   photo: farhan,
  //   name: 'Shakir Farhan',
  //   linkidin: 'https://www.linkedin.com/in/shak1rfarhan',
  //   twitter: 'https://twitter.com/shakirfarhan12',
  //   position: 'CTO',
  //   email: 'shakirfarhan28@gmail.com',
  // },
  {
    id: 52,
    photo: sneha,
    name: 'Sneha Vellelath',
    linkidin: 'https://www.linkedin.com/in/sneha-vellelath-2b6759207/',
    twitter: 'https://twitter.com/snehavellelath?t=jGTomn7JJAJwTFzG6an-WA&s=09',
    position: 'UI Designer',
    email: 'work.vellsneha@gmail.com',
  },
  // {
  //   id: 42,
  //   photo: riktha,
  //   name: 'Ch.Riktha Reddy',
  //   linkidin: 'https://www.linkedin.com/in/riktha-reddy-024b01221',
  //   twitter: 'https://twitter.com/snehavellelath?t=jGTomn7JJAJwTFzG6an-WA&s=09',
  //   position: 'UI Designer',
  //   email: 'Rikthawork@gmail.com',
  // },
  {
    id: 99,
    photo: rohit,
    name: 'Rohit Reddy',
    linkidin: 'https://www.linkedin.com/in/rohit-reddy-yerva-308513245/',
    twitter: 'https://twitter.com/RohitRYerva',
    position: 'UI Designer',
    email: 'work.rohityerva@gmail.com',
  },
  {
    id: 65,
    photo: mamtha,
    name: 'Mamtha Patalay',
    linkidin: 'https://www.linkedin.com/in/mamtha-patalay',
    twitter: 'https://twitter.com/mamtha_6',
    position: 'Frontend Developer',
    email: 'mamthapatalay6@gmail.com',
  },
  {
    id: 85,
    photo: anjana,
    name: ' Sai Anjana Perepi',
    linkidin: 'https://www.linkedin.com/in/aveironica939099257',
    twitter: 'https://twitter.com/snehavellelath?t=jGTomn7JJAJwTFzG6an-WA&s=09',
    position: 'Marketing and PR',
    email: 'saianjanaperepi@gmail.com',
  },
];
export const interests = [
  'Full Stack Development',
  'Application Development',
  'UI UX',
  'Artificial Intelligence',
  'Machine Learning',
  'Data Science',
  'Blockchain & Cryptocurrency',
  'Internet of Things (IOT)',
  'AR & VR',
  'Cybersecurity',
  'Sustainable Tech',
  'Robotics & Automation',
  'Edge Computing',
  'Quantum Computing',
  'Biotech & Healthcare',
  'Space Tech',
  'Market Research',
  'Business Planning',
  'Startup Funding',
  'Business Models',
  'Product Development',
  'Marketing-Branding',
  'Financial Management',
  'Leadership',
  'Innovation',
  'Networking',
  'Entrepreneurial Mindset',
  'Franchising',
  'Customer Satisfaction',
  'Public Relations',
  'Data Analytics',
];
export function formatDate(inputDate: Date) {
  const date = new Date(inputDate);

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const dayOfWeekAbbreviated = daysOfWeek[date.getUTCDay()];
  const dayOfMonth = date.getUTCDate();
  const monthAbbreviated = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return {
    day: dayOfWeekAbbreviated,
    date: `${dayOfMonth}${getDaySuffix(
      dayOfMonth
    )} ${monthAbbreviated} ${year}`,
    time: `${formattedHours}:${formattedMinutes} ${ampm}`,
  };
}

function getDaySuffix(day: number) {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}
export function formatBlogDate(inputDate: any) {
  const date = new Date(inputDate);
  const options: any = { year: 'numeric', month: 'long', day: '2-digit' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  return formattedDate;
}
