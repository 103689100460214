import axios, { AxiosInstance } from 'axios';
interface APIOptions {
  baseURL: string;
  headers?: any;
}

const API = (token?: string | null): AxiosInstance => {
  const headers = token ? { Authorization: token } : {};
  const options: APIOptions = {
    // baseURL: `${process.env.REACT_APP_SERVER_URL}/api/v1` || '',
    baseURL: process.env.REACT_APP_SERVER_URL as unknown as string,
    headers,
  };
  return axios.create(options);
};

export const verifyEmailLink = async (username: string, token: any) => {
  try {
    const apiInstance = API();
    const { data } = await apiInstance.get(
      `/user/auth/${username}/verify/${token}`
    );
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const googleAuth = async (tokenId: string) => {
  try {
    const { data } = await API().post('/user/auth/google-auth', {
      tokenId,
    });
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const isValidUser = async () => {
  try {
    const token = localStorage.getItem('userAccessToken');

    const { data } = await API(token).get('/user/auth/valid');

    return data;
  } catch (error) {
    console.log('Error in isValidUser api : ' + error);
  }
};
export const updateUser = async (body: any) => {
  try {
    const token = localStorage.getItem('userAccessToken');
    const { data } = await API(token).patch('/user/profile/me', body);

    return data;
  } catch (error) {
    console.log('Error in updateUser api : ' + error);
    return error;
  }
};
export const getUser = async (userId: string) => {
  try {
    const { data } = await API().get(`/public/users/user/${userId}`);
    return data;
  } catch (error) {
    console.log('Error in getUSer api : ' + error);
  }
};
