import axios from 'axios';
import {
  communityFormType,
  userFormType,
  loginFormType,
  signupFormType,
} from '../constants/interfaces';
import { toast } from 'react-toastify';
import { validateEmail } from '../utils/validations';
export const communityWaitlist = async (body: communityFormType) => {
  if (!body.name) return toast.warning('Provide First Name.');
  if (!body.leadName) return toast.warning('Provide Last Name.');
  if (!body.email) return toast.warning('Provide Email Address.');
  const emailValid = validateEmail(body.email);
  if (!emailValid) return toast.warning('Provide Valid Email Address.');
  console.log(body);
  if (body.leadPhoneNumber.length < 10)
    return toast.warning('Provide valid Phone Number');
  try {
    const { data } = await axios.post(
      'https://advantage-community-backend-l53x.onrender.com/api/v1/waitlist/community',
      {
        ...body,
        socials: [body.social],
      }
    );
    return data;
  } catch (error: any) {
    const { response } = error;
    if (response?.data?.message) {
      toast.warning(response?.data?.message);
    } else {
      toast.error('Something Went Wrong. Try Again!');
    }
  }
};
export const userWaitlist = async (body: userFormType) => {
  if (!body.firstName) return toast.warning('Provide First Name.');
  if (!body.lastName) return toast.warning('Provide Last Name.');
  if (!body.email) return toast.warning('Provide Email Address.');
  const emailValid = validateEmail(body.email);
  if (!emailValid) return toast.warning('Provide Valid Email Address.');
  try {
    const { data } = await axios.post(
      'https://advantage-community-backend-l53x.onrender.com/api/v1/waitlist/user',
      body
    );
    return data;
  } catch (error: any) {
    const { response } = error;
    if (response?.data?.message) {
      toast.warning(response?.data?.message);
    } else {
      toast.error('Something Went Wrong. Try Again!');
    }
  }
};

export const userLogin = async (body: loginFormType) => {
  if (!body.username) return toast.warning('Provide Username');
  if (!body.password) return toast.warning('Provide Password');
  return;
};
export const userSignUp = async (body: signupFormType) => {
  if (!body.firstname) return toast.warning('Provide First Name');
  if (!body.lastname) return toast.warning('Provide Last Name');
  if (!body.password) return toast.warning('Provide Password');
  if (!body.confirm_password) return toast.warning('Provide Confirm Password');
  return;
};
